.main {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-direction: column;
}

.error {
  color: var(--secondary-color);
  font-size: 2rem;
}
