.app {
  max-width: 400px;
  margin: 50px auto 0;
  text-align: center;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  line-height: 0;
  margin-bottom: 3em;
  margin-top: 3em;
}
